import React, { useCallback, useState } from "react";
import logo from "../img/vb-logo-png.png";
import { BrowserRouter as Router } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

export function Header() {
  const [click, setClick] = useState(false);

  const handleClick = useCallback(() => setClick(!click), [click]);

  return (
    <Router>
      <div className="header">
        <a className="nav-link" href="/">
          <img src={logo} alt="VB" height="60" />
        </a>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li>
            <a className="nav-link" href="/">
              Home
            </a>
          </li>
          <li>
            <a className="nav-link" href="/about">
              About
            </a>
          </li>
          <li>
            <a className="nav-link" href="/gallery">
              Work
            </a>
          </li>
          <li>
            <a className="nav-link" href="/contact">
              Contact
            </a>
          </li>
        </ul>
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={20} style={{ color: "#fff" }} />
          ) : (
            <FaBars size={20} />
          )}
        </div>
      </div>
    </Router>
  );
}
