import React from "react";
import ziva from "../img/ziva.png";
import gmail from "../img/gmail.png";
import github from "../img/github.png";
import linkedinBlue from "../img/linkedinBlue.png";

export function Contact() {
  return (
    <div className="contact container-fluid">
      <img className="ziva-contact" src={ziva} alt="Ziva" />
      <div className="btn-list">
        <a
          href="https://github.com/vbarcellos"
          className="btn btn-outline-dark btn-block contact-btn mb-2"
          target="_blank"
          rel="noreferrer"
        >
          <div className="row">
            <div className="col-4">
              <img src={github} alt="LinkedIn" height="50" />
            </div>
            <div className="col-6 fs-2">GitHub</div>
            <div className="col-2 " />
          </div>
        </a>

        <a
          href="https://www.linkedin.com/in/victor-barcellos-811677212/"
          className="btn btn-outline-primary btn-block contact-btn mb-2"
          target="_blank"
          rel="noreferrer"
        >
          <div className="row">
            <div className="col-4">
              <img src={linkedinBlue} alt="LinkedIn" height="50" />
            </div>
            <div className="col-8 fs-2">LinkedIn</div>
          </div>
        </a>

        <a
          href="mailto:victorfsb@gmail.com"
          className="btn btn-outline-danger btn-block contact-btn mb-2"
          target="_blank"
          rel="noreferrer"
        >
          <div className="row">
            <div className="col-4">
              <img src={gmail} alt="LinkedIn" height="50" />
            </div>
            <div className="col-6 fs-2">Email</div>
            <div className="col-2 " />
          </div>
        </a>
      </div>
    </div>
  );
}
