import React from "react";
import blueFolder from "../img/blue.png";
import greenFolder from "../img/green.png";
import greyFolder from "../img/grey.png";
import purpleFolder from "../img/purple.png";
import redFolder from "../img/red.png";
import yellowFolder from "../img/yellow.png";

export function Gallery(props) {
  return (
    <div class="gallery container overflow-hidden text-center">
      <div class="row gy-5">
        <div class="col-4">
          <b>Project 01</b>
          <div class="p-3">
            <img className="folder" src={blueFolder} alt="Project 01" />
          </div>
        </div>

        <div class="col-4">
          <b>Project 02</b>
          <div class="p-3">
            <img className="folder" src={greenFolder} alt="Project 01" />
          </div>
        </div>

        <div class="col-4">
          <b>Project 03</b>
          <div class="p-3">
            <img className="folder" src={greyFolder} alt="Project 01" />
          </div>
        </div>

        <div class="col-4">
          <b>Project 04</b>
          <div class="p-3">
            <img className="folder" src={purpleFolder} alt="Project 01" />
          </div>
        </div>

        <div class="col-4">
          <b>Project 05</b>
          <div class="p-3">
            <img className="folder" src={redFolder} alt="Project 01" />
          </div>
        </div>

        <div class="col-4">
          <b>Project 06</b>
          <div class="p-3">
            <img className="folder" src={yellowFolder} alt="Project 01" />
          </div>
        </div>
      </div>
    </div>
  );
}
