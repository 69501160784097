import React from "react";
import { About } from "./components/About";
import { Gallery } from "./components/Gallery";
import { Header } from "./components/Header";
import { Home } from "./components/Home";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Contact } from "./components/Contact";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: (
      <div>
        <div>Something went wrong...</div>
      </div>
    ),
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "gallery",
    element: <Gallery />,
  },
  {
    path: "contact",
    element: <Contact />,
  },
]);

export function App(props) {
  return (
    <div className="App container-fluid">
      <Header />
      <RouterProvider router={router} />
    </div>
  );
}
