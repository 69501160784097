import React from "react";
import victor from "../img/victorCut.png";

export function About(props) {
  return (
    <div className="about">
      <div>
        <div className="mb-3">
          <img className="victor" src={victor} alt="Thats me!" />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
          placerat porttitor ipsum id lobortis. Nam rhoncus a quam nec
          dignissim. Vivamus sit amet erat nisi. Phasellus elit sem, consectetur
          at porttitor quis, pretium ut metus. Ut pharetra, diam sit amet
          laoreet feugiat, urna turpis efficitur augue, vitae dignissim risus
          quam non magna. Mauris cursus eu dolor id ultricies. Aenean neque
          nisi, placerat ut hendrerit ullamcorper, vulputate vel nibh. Praesent
          vitae nulla sem. Vivamus in malesuada est, a egestas tortor. Donec
          lacinia, lorem quis imperdiet aliquet, erat est eleifend est, et
          pulvinar ipsum erat in velit. Nullam bibendum nec velit id dictum.
          Morbi dictum euismod urna, nec varius nulla aliquam laoreet.
          Suspendisse lacinia condimentum lectus, in ultricies lorem varius in.
          Praesent vel risus tristique, tempor massa a, molestie ante.
          Pellentesque mi turpis, egestas ac congue id, suscipit vel mi.
        </div>

        <div className="mb-3">
          Ut consequat nisi dui, ut aliquam elit posuere vel. Maecenas faucibus
          dapibus diam vel sodales. Quisque finibus, ipsum sed lobortis lacinia,
          neque augue dapibus lectus, ut aliquam est mauris et dolor. Proin
          varius et neque non pulvinar. Vivamus non quam viverra, vulputate
          lectus vitae, mollis nibh. Maecenas interdum feugiat massa quis
          laoreet. Nam consectetur arcu non nisi ornare, nec vehicula velit
          sagittis. Orci varius natoque penatibus et magnis dis parturient
          montes, nascetur ridiculus mus. Sed volutpat risus nec nibh aliquam,
          non finibus massa fermentum. Nam nec erat felis. Pellentesque habitant
          morbi tristique senectus et netus et malesuada fames ac turpis
          egestas. Cras eu imperdiet orci. Quisque rutrum turpis orci, at
          scelerisque diam laoreet lobortis. Donec ac tellus nibh.
        </div>

        <div className="mb-3">
          Sed orci odio, pulvinar in metus ultrices, laoreet rhoncus nisi.
          Praesent vestibulum est et erat dapibus, vel varius velit cursus.
          Maecenas molestie lectus erat, non sodales nunc ultrices ac. Cras
          aliquet, libero ut ultrices placerat, odio velit suscipit sapien, id
          viverra ligula sem id dolor. Sed pretium rutrum accumsan. Vivamus eu
          metus vehicula, consectetur neque ut, lacinia elit. Cras ac
          scelerisque libero. Fusce hendrerit dui vitae purus volutpat sodales.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dapibus
          mi dolor, eget tempus felis facilisis eu. Fusce sed ante a mauris
          vehicula lacinia ut ut ante. Praesent feugiat massa eros, in varius
          lorem ornare quis. Class aptent taciti sociosqu ad litora torquent per
          conubia nostra, per inceptos himenaeos. Nullam id arcu neque. Vivamus
          cursus porta sapien, at pharetra quam hendrerit sit amet.
        </div>
      </div>
    </div>
  );
}
