import React from "react";
import zivaInv from "../img/zivaInv.png";

export function Home() {
  return (
    <div className="home container-fluid">
      <div>
        <div className="py-2 h1">Hello!</div>
        <div className="h5">
          <span className="text-wrap">
            I'm <b>Victor Barcellos</b>, a full stack <br /> software developer
            based in Brazil.
          </span>
        </div>
      </div>

      <img className="ziva" src={zivaInv} alt="Ziva" />
    </div>
  );
}
